import React from "react"
import PostLink from "../postlink/postlink"

import { graphql, StaticQuery } from "gatsby"

import "./postlist.scss"

interface IFrontmatter {
  date: string,
  path: string,
  title: string,
  strapline: string
}

interface INode {
  id: string,
  excerpt: string,
  frontmatter: IFrontmatter
}

interface IEdge {
  node: INode
}

const PostList = () => {
  return (
    <section className="post-list">
      <h2>Recent Blog Posts</h2>
      <ul className="post-list__list">
        <StaticQuery
          query={graphql`
            query PostListQuery {
              allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
                edges {
                  node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                      date(formatString: "MMMM DD, YYYY")
                      path
                      title
                      strapline
                    }
                  }
                }
              }
            }
          `}
          render={( data ) => {
            const edges: IEdge[] = data.allMarkdownRemark.edges
            return edges
              .filter((edge : IEdge) => !!edge.node.frontmatter.date)
              .map((edge : IEdge, index : number) => <li className="post-list__list-item" key={index}><PostLink key={edge.node.id} post={edge.node} /></li>)
          }}
        />
      </ul>
    </section>
  )
}

export default PostList
