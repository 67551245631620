import React from "react"
import { Link } from "gatsby"

import "./postlink.scss"

const PostLink = ({ post } : any ) => {
  return (
    <div className="post-link">
      <Link className="post-link__link" to={post.frontmatter.path}>
        <h3 className="post-link__title">{post.frontmatter.title}</h3>
        <time className="post-link__date" dateTime={(new Date(post.frontmatter.date)).toISOString()}>{post.frontmatter.date}</time>
        <div className="post-link__strapline">{post.frontmatter.strapline}</div>
      </Link>
    </div>
  )
}

export default PostLink