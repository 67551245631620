import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PostList from "../components/postlist/postlist"

const IndexPage = () => (
  <Layout title="Home">
    <section>
      <h1>Hello.</h1>
      <p>
        This is a development blog focusing on web technologies. I'll show
        you how to achieve certain tasks using frameworks such as Sitecore
        and GatsbyJS.
      </p>
    </section>
    <PostList />
  </Layout>
)

export default IndexPage
